<template>
  <div id="divProfitByCategoryWrapper" class="content-layout-fixer">
    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueWrapperNarrow>
      <VueForm class="form-holder" ref="form">
        <VueInput
          class="input-wrapper"
          name="purchase-price"
          id="purchase-price"
          type="text"
          label="Alış Fiyatınız"
          v-model="formModel.priceOfPurchase"
          :messageOnTop="true"
          validationRules="required|decimal"
          validatorName="Alış fiyatı"
        ></VueInput>
        <VueInput
          class="input-wrapper"
          name="selling-price"
          id="selling-price"
          type="text"
          label="Satış Fiyatınız"
          v-model="formModel.priceOfSales"
          :messageOnTop="true"
          validationRules="required|decimal"
          validatorName="Satış fiyatı"
        ></VueInput>
        <VueInput
          class="input-wrapper"
          name="number-of-sales"
          id="number-of-sales"
          type="text"
          label="Satış Adedi"
          v-model="formModel.numberOfSales"
          :messageOnTop="true"
          validationRules="required|numeric"
          validatorName="Satış adedi"
        ></VueInput>
      </VueForm>
      <BrandButton
        class="calc-button"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        :padding="{ vertical: 15, horizontal: 0 }"
        @click="handleSubmit"
        >HESAPLA</BrandButton
      >
      <div v-if="showResultView" class="result-holder">
        <VueText class="turnover-amount" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.topValue | currencyFormat }} TL</VueText
        >
        <VueText class="turnover-text" color="grey-40" sizeLevel="11">
          {{ resultViewItems.topTitle }}</VueText
        >
        <VueText class="endorsement-amount" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.bottomValue }} TL</VueText
        >
        <VueText class="endorsement-text" color="grey-40" sizeLevel="11">
          {{ resultViewItems.bottomTitle }}</VueText
        >
      </div>
      <BrandButton
        :outlined="true"
        v-if="showResultView"
        @click="submitResultPopUp"
        :size="sizes.xxLarge"
        >ÖĞRENMEYE DEVAM ET</BrandButton
      >
    </VueWrapperNarrow>
  </div>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import currencyFilter from '@/mixins/currencyFilter.js';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'EndorsementByCategory',
  components: {
    Header,
    VueForm,
    VueInput,
    VueText,
    BrandButton,
    VueWrapperNarrow,
  },
  mixins: [currencyFilter, gtmUtils],
  data() {
    return {
      resultViewItems: {
        topTitle: 'Her Ziyaretteki Fatura Tutarınıza Göre Yıllık Cironuz',
        topValue: 0,
        bottomTitle: 'Her Ziyaretteki Fatura Tutarınıza Göre Yıllık Kârınız',
        bottomValue: 0,
      },
      showResultView: false,
      formModel: {
        priceOfPurchase: '',
        priceOfSales: '',
        numberOfSales: '',
      },
      headerContent: [
        {
          headline: 'Hangi üründen ne kadar kâr ediyorsunuz?',
          text: 'Lütfen sattığınız ürünün alış ve satış fiyatlarını giriniz.',
        },
      ],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    calculateTotalRevenueAndProfit() {
      let pop = this.formModel.priceOfPurchase.replace(',', '.');
      let pos = this.formModel.priceOfSales.replace(',', '.');
      let nos = this.formModel.numberOfSales;
      this.resultViewItems.bottomValue = (pos - pop) * nos;
      this.resultViewItems.topValue = pos * nos;
      this.pushDataLayerEvent('learn', {
        event: 'product_profit_calculation',
        sales_quantity: nos,
        buying_amount: pop,
        selling_amount: pos,
        total_profit: this.resultViewItems.bottomValue,
        total_revenue: this.resultViewItems.topValue,
      });
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.calculateTotalRevenueAndProfit();
        this.showResultView = true;
        this.$nextTick(() => {
          const result = document.querySelector('.result-holder');
          result.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/`);
    },
  },
};
</script>

<style lang="scss">
.form-holder {
  margin-top: palette-space-level(60);

  .input-wrapper {
    margin-bottom: palette-space-level(20);
  }
}

.calc-button {
  margin-top: palette-space-level(10);
}

.result-holder {
  padding: palette-space-level(30) 0 palette-space-level(45);
  margin: palette-space-level(70) 0 palette-space-level(30);
  background-color: palette-color-level('grey', '10');
  text-align: center;

  .endorsement-amount,
  .turnover-amount {
    padding-bottom: palette-space-level(30);
  }

  .turnover-text {
    margin-bottom: palette-space-level(80);
  }
}
</style>
